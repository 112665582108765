<template>
  <div id="kundenDetail">
    <v-container fill-height fluid height="90vh">
      <v-row align="center" justify="center">
        <v-col :cols="colLimit">
          <v-card v-if="customer" class="ma-8 pa-8 mt-16" flat>
            <v-container fluid>
              <v-row
                ><v-col :cols="colWidth" class="py-0"> <h3>Persönliche Daten</h3></v-col>
                <v-spacer />
                <v-col
                  cols="auto"
                  class="py-0"
                  style="display: flex; align-items: center"
                >
                  <v-btn color="error" @click="showDeletionWarning = true"
                    ><v-icon>mdi-delete</v-icon> Löschen
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="colWidth / 2" class="py-0">
                  <v-text-field label="Firma" v-model="customer.company"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="colWidth / 2" class="py-3">
                  <v-select
                    :items="salutations"
                    label="Anrede"
                    v-model="customer.salutation"
                  ></v-select>
                </v-col>
                <v-col :cols="colWidth / 2" class="py-3">
                  <v-text-field
                    label="Vorname"
                    v-model="customer.first_name"
                  ></v-text-field>
                </v-col>
                <v-col :cols="colWidth / 2" class="py-3">
                  <v-text-field label="Name" v-model="customer.last_name"></v-text-field>
                </v-col>
                <v-col :cols="colWidth / 2" style="display: flex; align-items: center">
                  <v-menu
                    class="py-0"
                    ref="birthdayMenu"
                    v-model="birthdayMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-on="on"
                        class="pa-0 pr-3"
                        style="width: 30px; height: 30px"
                      >
                        mdi-calendar
                      </v-icon>
                      <v-text-field
                        :value="getReadableDate(customer.birthday)"
                        label="Geburtstag"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="customer.birthday"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1920-01-01"
                      @change="saveBirthday"
                      locale="de-DE"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row
                ><v-col :cols="colWidth" class="py-0">
                  <h3>Adressdaten</h3>
                </v-col></v-row
              >
              <v-row>
                <v-col :cols="colWidth / 2" class="py-0">
                  <v-text-field label="Straße" v-model="customer.address1"></v-text-field>
                </v-col>
                <v-col :cols="colWidth / 2" class="py-0">
                  <v-text-field
                    label="Adresszusatz"
                    v-model="customer.address2"
                  ></v-text-field>
                </v-col>
                <v-col :cols="colWidth / 2" class="py-0">
                  <v-text-field
                    label="Postleitzahl"
                    v-model="customer.postcode"
                    :rules="numericRules"
                  ></v-text-field>
                </v-col>

                <v-col :cols="colWidth / 2" class="py-0">
                  <v-text-field label="Ort" v-model="customer.city"></v-text-field>
                </v-col>
                <v-col :cols="colWidth / 2" class="py-0">
                  <v-text-field label="Land" v-model="customer.country"></v-text-field>
                </v-col>
              </v-row>

              <v-row
                ><v-col :cols="colWidth" class="py-0">
                  <h3>Kontaktdaten</h3>
                </v-col></v-row
              >
              <v-row>
                <v-col :cols="colWidth / 2" class="py-0">
                  <v-text-field label="E-Mail" v-model="customer.mail"></v-text-field>
                </v-col>
                <v-col :cols="colWidth / 2" class="py-0">
                  <v-text-field label="Telefon" v-model="customer.phone"></v-text-field>
                </v-col>
                <v-col :cols="colWidth / 2" class="py-0">
                  <v-text-field label="Mobil" v-model="customer.mobile"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  :cols="colWidth / 2"
                  class="py-0"
                  style="display: flex; align-items: center"
                >
                  <v-switch
                    class="my-1"
                    v-model="customer.send_notification_mail"
                    inset
                    label="Erinnerungs E-Mail?"
                  ></v-switch>
                </v-col>
                <v-col
                  :cols="colWidth / 2"
                  class="py-0"
                  style="display: flex; align-items: center"
                >
                  <v-switch
                    class="my-1"
                    v-model="customer.vip"
                    inset
                    label="VIP"
                  ></v-switch>
                </v-col>
                <v-col
                  :cols="colWidth / 2"
                  class="py-0"
                  style="display: flex; align-items: center"
                >
                  <v-switch
                    class="my-1"
                    v-model="customer.has_agreed_to_mail"
                    inset
                    label="Online Terminbuchung"
                  ></v-switch>
                </v-col>
              </v-row>

              <br />
              <br />
              <br />

              <v-divider />

              <br />
              <br />

              <v-row>
                <v-col :cols="colWidth / 2" class="py-0">
                  <h3>Fahrzeuge</h3>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="py-0">
                  <v-btn @click="showHistory = true" text color="primary"
                    ><v-icon>mdi-history</v-icon></v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="colWidth">
                  <v-hover v-slot="{ hover }">
                    <v-card
                      :elevation="hover ? 16 : 2"
                      height="300px"
                      @click="newVehicleOverlay = true"
                    >
                      <v-card-title style="padding: 18px"
                        ><div style="font-size: x-large">
                          Neues Fahrzeug
                        </div></v-card-title
                      >
                      <v-divider />
                      <v-card-text
                        style="
                          display: flex;
                          color: lightgray;
                          align-items: center;
                          justify-content: center;
                          height: 236px;
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="150px"
                          height="150px"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                          />
                        </svg>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col
                  :cols="colWidth"
                  v-for="vehicle in customer.vehicle_set"
                  :key="vehicle.id"
                  :style="{ display: vehicle.deleted ? 'none' : 'block' }"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      :elevation="hover ? 16 : 2"
                      height="300px"
                      @click="handleClickOnVehicle(vehicle)"
                    >
                      <v-card-title>
                        <div style="font-size: x-large">{{ vehicle.plate }}</div>
                        <v-icon
                          color="red"
                          style="font-size: xx-large"
                          v-if="vehicle.need_new_tire"
                          >mdi-alert</v-icon
                        >
                        <v-spacer />
                        <v-btn
                          v-if="checkPerm(4)"
                          @click="gotoAppointmentForVehicle(vehicle.id)"
                          class="basicButton"
                          ><v-icon>mdi-calendar</v-icon></v-btn
                        ></v-card-title
                      >
                      <v-divider />
                      <v-card-text>
                        <h3>{{ (vehicle.model || "") + " " + (vehicle.type || "") }}</h3>
                        <br />
                        Nächste Inspektion: <b>{{ vehicle.inspection_date }}</b> <br />
                        <div v-for="i in vehicle.tire_storage" :key="i.id">
                          Lagerplatz: <b> {{ i.space }} </b> <br />
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="newVehicleOverlay" :dark="false">
      <NewVehicle :customerData="customer" @hide="closeNewVehicle" />
    </v-overlay>

    <div class="fullScreenOverlay" v-if="showDeletionWarning">
      <div class="overlayBackground"></div>
      <WarnBanner
        class="overlayDisplay"
        @cancel="showDeletionWarning = false"
        @approve="deleteFromDB()"
        :action="'löschen'"
        :text="'diesen Kunden'"
        :additional="'Auch alle seine/ihre Fahrzeuge werden gelöscht'"
      />
    </div>

    <div class="fullScreenOverlay" v-if="showHistory">
      <div class="overlayBackground"></div>
      <CustomerHistory
        class="overlayDisplay"
        @cancel="showHistory = false"
        :customerID="customer.id"
      />
    </div>
  </div>
</template>

<script>
import { getReadableDate } from "../../js/helperFunctions.js";
import NewVehicle from "../Vehicle/NewVehicle.vue";
import WarnBanner from "../Misc/WarnBanner.vue";
import CustomerHistory from "./CustomerHistory.vue";

const axios = require("axios");

export default {
  name: "customerDetail",

  data() {
    return {
      salutations: ["Herr", "Frau", "Divers"],

      customer: undefined,

      showBirthdayPicker: false,
      activePicker: null,
      birthdayMenu: false,

      currentVehicleData: undefined,

      newVehicleOverlay: false,
      showDeletionWarning: false,
      showHistory: false,

      numericRules: [(v) => !v || !isNaN(parseInt(v)) || "Nummer!"],
    };
  },

  components: {
    NewVehicle,
    WarnBanner,
    CustomerHistory,
  },

  watch: {
    "$route.params.id": function () {
      this.requestCustomerData();
    },
    "$route.params.vehicle": function () {
      this.requestCustomerData();
    },
    birthdayMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  computed: {
    colLimit() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 10;
        case "md":
          return 8;
        case "lg":
          return 9;
        case "xl":
          return 6;
        default:
          return 8;
      }
    },
    colWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 4;
        case "xl":
          return 4;
        default:
          return 4;
      }
    },
  },

  methods: {
    checkPerm(val) {
      if (sessionStorage.getItem("permission") <= 2) return true;

      return sessionStorage.getItem("permission") == val;
    },
    deleteFromDB() {
      for (let i = 0; i < this.customer.vehicle_set.length; i++) {
        if (this.customer.vehicle_set[i].tire_storage.length > 0) {
          window.alert("Eines der Fahrzeuge hat noch eingelagerte Reifen!");
          this.showDeletionWarning = false;
          return;
        }
      }

      axios
        .patch(axios.defaults.baseURL + "customers/" + this.customer.id, {
          id: this.customer.id,
          deleted: true,
        })
        .then(() => {
          this.showDeletionWarning = false;

          this.$router.push("/dashboard");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    closeNewVehicle(data) {
      if (data) {
        this.$router.push("/fahrzeug/" + this.customer.id + "/" + data.id);
      } else {
        this.newVehicleOverlay = false;
      }
    },
    gotoAppointmentForVehicle(vehicleID) {
      this.$store.state.appointmentVehicleID = {
        vehicleID: vehicleID,
        timestamp: Date.now(),
      };
      this.$router.push("/termine");
    },
    handleClickOnVehicle(vehicleData) {
      this.currentVehicleData = vehicleData;

      this.$router.push(
        "/fahrzeug/" + this.customer.id + "/" + this.currentVehicleData.id
      );
    },
    applyDataFromVehicleOverlay(data) {
      if (data) {
        if (this.customer && this.customer.vehicle_set) {
          for (let i = 0; i < this.customer.vehicle_set; i++) {
            if (this.customer.vehicle_set[i].id == data.id) {
              this.customer.vehicle_set[i] = data;
              return;
            }
          }

          this.customer.vehicle_set.push(data);
          this.$forceUpdate();
        } else {
          this.requestCustomerData();
        }
      }
    },
    getReadableDate: getReadableDate,
    sendCustomerData() {
      let formData = {
        salutation:
          this.salutations.indexOf(this.customer.salutation) >= 0
            ? this.salutations.indexOf(this.customer.salutation)
            : null,
        title: this.customer.title,
        last_name: this.customer.last_name,
        first_name: this.customer.first_name,
        birthday: this.customer.birthday,
        company: this.customer.company,
        city: this.customer.city,
        country: this.customer.country,
        address1: this.customer.address1,
        address2: this.customer.address2,
        postcode: this.customer.postcode,
        mail: this.customer.mail,
        phone: this.customer.phone,
        mobile: this.customer.mobile,
        send_notification_mail: this.customer.send_notification_mail,
        vip: this.customer.vip,
        has_agreed_to_mail: this.customer.has_agreed_to_mail,
      };

      axios
        .patch(axios.defaults.baseURL + "customers/" + this.customer.id, formData)
        .then(() => {})
        .catch((e) => console.log(e));
    },
    saveBirthday(date) {
      this.$refs.birthdayMenu.save(date);
    },
    requestCustomerData() {
      axios
        .get(axios.defaults.baseURL + "customers/" + this.$route.params.id)
        .then((resp) => {
          this.customer = resp.data;
          this.customer.salutation = this.salutations[this.customer.salutation];

          for (let i = 0; i < this.customer.vehicle_set.length; i++) {
            let vehicle = this.customer.vehicle_set[i];
            vehicle.tire_storage = vehicle.tire_storage.replace(/'/g, '"');
            let tireStorageData = JSON.parse(vehicle.tire_storage);

            for (let j = 0; j < tireStorageData.length; j++) {
              let temp = tireStorageData[j].space.split(" ");
              tireStorageData[j].space = temp[0] + " R" + temp[1] + " P" + temp[2];
            }

            vehicle.tire_storage = tireStorageData;
          }

          if (this.$route.params.vehicle != undefined) {
            if (this.$route.params.vehicle != 0) {
              for (let i = 0; i < this.customer.vehicle_set.length; i++) {
                if (this.customer.vehicle_set[i].id == this.$route.params.vehicle) {
                  this.currentVehicleData = this.customer.vehicle_set[i];
                }
              }
            }

            this.$router.push("/kunden/" + this.customer.id);
          }

          if (this.$route.params.newVehicle) {
            this.newVehicleOverlay = true;
          }
        })
        .catch((e) => console.log(e));
    },
  },

  mounted() {
    this.requestCustomerData();
  },

  updated() {
    this.sendCustomerData();
  },
};
</script>
