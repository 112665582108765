var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dashboard"}},[_c('v-container',{class:{
      centerOnScreen: _vm.smallerContainer ? true : false,
      dashBoardSmallScreen: _vm.smallerContainer ? false : true,
    }},[_c('v-row',{staticStyle:{"display":"flex","justify-content":"space-around","margin":"0 auto"},style:({ 'max-width': _vm.smallerContainer ? '75%' : '100%' })},[_c('v-col',{attrs:{"cols":_vm.cols}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2,"height":"260px"},on:{"click":_vm.gotoNewCustomer}},[_c('v-container',{staticClass:"text-center pt-1",staticStyle:{"height":"204px","display":"flex","justify-content":"space-evenly","flex-direction":"column"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-4"},[_c('v-spacer'),_c('v-col',{staticClass:"pa-0 mx-0 mt-15",attrs:{"cols":"4"}},[_c('p',{staticClass:"pa-0 ma-0",attrs:{"color":"primary"}},[_vm._v("Kunden")])]),_c('v-col',{staticClass:"pa-0 mx-0 mt-15",attrs:{"cols":"4"}},[_c('p',{staticClass:"pa-0 ma-0",attrs:{"color":"primary"}},[_vm._v("Fahrzeuge")])]),_c('v-spacer')],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pt-0 mb-10",attrs:{"cols":"4"}},[_c('h2',[_vm._v(_vm._s(_vm.customerCount))])]),_c('v-col',{staticClass:"pt-0 mb-10",attrs:{"cols":"4"}},[_c('h2',[_vm._v(_vm._s(_vm.vehicleCount))])]),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('div',{staticClass:"alignSameRow clickable"},[_c('div',[_c('b',[_vm._v("Kunden")])])])])],1)]}}])})],1),(_vm.checkPerm(4))?_c('v-col',{attrs:{"cols":_vm.cols}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"260px","elevation":hover ? 16 : 2},on:{"click":function($event){return _vm.gotoAppointments()}}},[_c('v-container',{staticClass:"text-center",staticStyle:{"height":"204px"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-4"},[_c('v-spacer'),_c('v-col',{staticClass:"pa-0 mx-0 mt-15",attrs:{"cols":"4"}},[_c('p',{staticClass:"pa-0 ma-0",attrs:{"color":"primary"}},[_vm._v("Woche")])]),_c('v-col',{staticClass:"pa-0 mx-0 mt-15",attrs:{"cols":"4"}},[_c('p',{staticClass:"pa-0 ma-0",attrs:{"color":"primary"}},[_vm._v("Heute")])]),_c('v-spacer')],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pt-0 mb-10",attrs:{"cols":"4"}},[_c('h2',[_vm._v(_vm._s(_vm.appointmentsCount_week))])]),_c('v-col',{staticClass:"pt-0 mb-10",attrs:{"cols":"4"}},[_c('h2',[_vm._v(_vm._s(_vm.appointmentsCount_day))])]),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('b',[_vm._v("Termine")])])],1)]}}],null,false,2400876402)})],1):_vm._e(),(_vm.checkPerm(2))?_c('v-col',{attrs:{"cols":_vm.cols}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"260px","elevation":hover ? 16 : 2},on:{"click":function($event){return _vm.gotoStorage()}}},[_c('v-container',{staticClass:"text-center",staticStyle:{"height":"204px"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-4"},[_c('v-spacer'),_c('v-col',{staticClass:"pa-0 mx-0 mt-15",attrs:{"cols":"4"}},[_c('p',{staticClass:"pa-0 ma-0",attrs:{"color":"primary"}},[_vm._v("Kapazität")])]),_c('v-col',{staticClass:"pa-0 mx-0 mt-15",attrs:{"cols":"4"}},[_c('p',{staticClass:"pa-0 ma-0",attrs:{"color":"primary"}},[_vm._v("Frei")])]),_c('v-spacer')],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pt-0 mb-10",attrs:{"cols":"4"}},[_c('h2',[_vm._v(_vm._s(_vm.totalSpaces))])]),_c('v-col',{staticClass:"pt-0 mb-10",attrs:{"cols":"4"}},[_c('h2',[_vm._v(_vm._s(_vm.totalSpaces - _vm.occupiedSpaces))])]),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('b',[_vm._v("Lager")])])],1)]}}],null,false,132126901)})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.cols}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"260px","elevation":hover ? 16 : 2}},[_c('v-container',{staticClass:"text-center",class:{ innerCard: _vm.expiredTires.length > 3 },staticStyle:{"height":"204px"},attrs:{"fluid":""}},[(_vm.expiredTires.length == 0)?_c('p',{staticStyle:{"color":"lightgray"}},[_vm._v(" Keine Reifen länger als 2 Jahre im Lager! ")]):_vm._e(),_vm._l((_vm.expiredTires),function(tire){return _c('div',{key:tire.id,on:{"click":function($event){return _vm.gotoVehicle(tire.Vehicle.Customer.id, tire.Vehicle.id)}}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('div',{staticClass:"text-center",staticStyle:{"display":"flex","justify-content":"center","font-size":"large"}},[_c('b',{staticClass:"pr-2"},[_vm._v(_vm._s(tire.Vehicle.plate))]),_vm._v(" "+_vm._s(tire.Vehicle.model)+" ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',[_vm._v(_vm._s(tire.tire_storage + ","))]),_c('b',{staticClass:"ml-2"},[_vm._v("seit "+_vm._s(_vm.getReadableDate(tire.storage_date)))])])])],1),_c('v-divider',{staticClass:"mb-1"})],1)})],2),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('div',[_c('b',[_vm._v("Reifenlagerzeit")])])])],1)]}}])})],1),(_vm.checkPerm(2))?_c('v-col',{attrs:{"cols":_vm.cols}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"260px","elevation":hover ? 16 : 2}},[(_vm.recentRequests.length > 0)?_c('v-container',{staticClass:"text-center pt-1",staticStyle:{"height":"204px","display":"flex","justify-content":"space-evenly","flex-direction":"column"},attrs:{"fluid":""}},_vm._l((_vm.recentRequests),function(request){return _c('v-row',{key:request.id,staticClass:"pa-0 mt-0"},[_c('v-spacer'),_c('v-col',{staticClass:"pb-0 pt-1 px-4",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-0",staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"text":"","block":""},on:{"click":function($event){return _vm.showRequestInCal(request, $event)}}},[_c('div',{staticStyle:{"letter-spacing":"normal"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-calendar-blank")]),_vm._v(" "+_vm._s(request.Vehicle.plate)+" ")],1),_c('div',{staticClass:"pb-1 pr-1",staticStyle:{"letter-spacing":"normal"}},[_vm._v(" "+_vm._s(_vm.getReadableDate(request.date))+" ")])])],1),_c('v-spacer')],1)}),1):_vm._e(),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('b',[_vm._v("Offene Terminanfragen")])])],1)]}}],null,false,2118228211)})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.cols}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"260px","elevation":hover ? 16 : 2}},[_c('v-container',{staticClass:"text-center",class:{ innerCard: _vm.tuv.length > 3 },staticStyle:{"height":"204px"},attrs:{"fluid":""}},[(_vm.tuv.length == 0)?_c('p',{staticStyle:{"color":"lightgray"}},[_vm._v(" Keine Kunden mit TÜV/AU in den nächsten 30 Tagen! ")]):_vm._e(),_vm._l((_vm.tuv),function(t){return _c('div',{key:t.id},[_c('v-row',{staticClass:"ma-0 pa-0",on:{"click":function($event){return _vm.gotoVehicle(t.Customer, t.id)}}},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('div',{staticClass:"text-center",staticStyle:{"display":"flex","justify-content":"center","font-size":"large"}},[_c('b',{staticClass:"pr-2"},[_vm._v(_vm._s(t.plate))]),_vm._v(" "+_vm._s(t.model)+" ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('b',{staticClass:"ml-2"},[_vm._v("fällig "+_vm._s(_vm.getReadableDate(t.inspection_date)))])])])],1),_c('v-divider',{staticClass:"mb-1"})],1)})],2),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('b',[_vm._v("TÜV/AU Fälligkeit")])])],1)]}}])})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.customerOverlayVisible,"dark":false}},[_c('NewCustomerOverlay',{on:{"hide":function($event){_vm.customerOverlayVisible = false},"created":_vm.newCustomerCreated}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }